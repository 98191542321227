import initialState from './initialState'

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_COMPARISON_BOARD':
      return action.comparisonBoard
    case 'UPDATE_COMPARISON_BOARD_NODE_POPUP_IS_LOADING':
      return {
        ...state,
        nodePopup: {
          ...state.nodePopup,
          isLoading: action.isLoading,
        },
      }
    default:
      return state
  }
}

export default reducer
