const initialState = {
  searchkit: null,
  context: -1,
  hitsView: 0,
  lastRemovedItem: 0,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_SEARCHKIT_OBJECT':
      return {
        ...state,
        searchkit: action.searchkit,
        context: action.context,
      }
    case 'UPDATE_HIST_VIEW':
      return {
        ...state,
        hitsView: action.hitsView,
      }
    case 'UPDATE_LAST_REMOVED_ITEM':
      return {
        ...state,
        lastRemovedItem: action.lastRemovedItem,
      }
    default:
      return state
  }
}

export default reducer
