import { combineReducers } from 'redux'

import projects from './projects/reducers'
import products from './products/reducers'
import search from './search/reducers'
import searchProducts from './searchProducts/reducers'
import editProject from './editProject/reducers'
import createProduct from './createProduct/reducers'
import comparisonBoard from './comparisonBoard/reducers'

const rootReducer = combineReducers({
  projects,
  products,
  search,
  searchProducts,
  editProject,
  createProduct,
  comparisonBoard,
})

export default rootReducer
