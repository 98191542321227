const initialState = {
  isLastVersion: true,
  githash: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_APP_VERSION':
      return {
        ...state,
        isLastVersion: action.isLastVersion,
        githash: action.githash,
      }
    case 'UPDATE_IS_LAST_VERSION':
      return {
        ...state,
        isLastVersion: action.isLastVersion,
      }
    case 'UPDATE_GIT_HASH':
      return {
        ...state,
        githash: action.githash,
      }
    default:
      return state
  }
}

export default reducer
