const initialState = {
  newProductId: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RPM/CREATE_PRODUCT/INIT': {
      return {
        ...state,
        newProductId: action.newProductId,
      }
    }
    case 'RPM/CREATE_PRODUCT/RESET': {
      return {
        ...state,
        newProductId: null,
      }
    }
    default:
      return state
  }
}

export default reducer
