import _ from 'lodash'
import { SYNC_STATUS_IDLE, SYNC_STATUS_IN_PROGRESS, SYNC_STATUS_COMPLETED } from '../../../consts/sync'

const initialState = {
  items: {}, // The queue of items indexed by ID
  errors: [], // A list of errors related to the queue items
  status: SYNC_STATUS_IDLE, // Could be 'idle', 'in_progress' or 'completed'
}

const isSyncCompleted = items => {
  let isCompleted = true

  _.each(items, item => {
    if (item.completed_at === null) {
      isCompleted = false
    }
  })

  return isCompleted
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_QUEUE_ITEMS': {
      const alreadyAddedKeys = _.keys(state.items)
      const newlyAddedItems = _.filter(action.items, item => !alreadyAddedKeys.includes(item.id))
      const indexedNewlyAddedItems = _.keyBy(newlyAddedItems, 'id')
      const newlyAddedKeys = _.keys(indexedNewlyAddedItems)

      return {
        ...state,
        items: { ...state.items, ...indexedNewlyAddedItems },
        status: SYNC_STATUS_IN_PROGRESS,
        // Remove all errors already present that are related to the newly added items
        errors: _.filter(state.errors, error => !newlyAddedKeys.includes(error.itemId)),
      }
    }

    case 'RESET_QUEUE_ITEM': {
      const { id: removedItemId, syncKey } = action

      const newItems = { ...state.items }
      newItems[removedItemId].completed_at = null
      newItems[removedItemId].sync_key = syncKey

      const newErrors = _.filter(state.errors, error => error.itemId !== removedItemId)

      return {
        ...state,
        items: newItems,
        errors: newErrors,
        status: SYNC_STATUS_IN_PROGRESS,
      }
    }

    case 'REMOVE_QUEUE_ITEMS': {
      const { ids: removedItemIds } = action

      const newItems = _.filter(state.items, (item, key) => !_.includes(removedItemIds, key))
      const newErrors = _.filter(state.errors, error => !_.includes(removedItemIds, error.itemId))

      let newStatus = state.status
      if (newItems.length === 0) {
        newStatus = SYNC_STATUS_IDLE
      } else if (isSyncCompleted(newItems)) {
        newStatus = SYNC_STATUS_COMPLETED
      }

      return {
        ...state,
        items: _.keyBy(newItems, 'id'),
        errors: newErrors,
        status: newStatus,
      }
    }

    case 'QUEUE_ITEM_PROCESSED_SUCCESSFULLY': {
      const { id: processedItemId } = action
      const processedItem = _.find(state.items, item => item.id === processedItemId)
      processedItem.completed_at = Date.now()

      const newItems = { ...state.items }
      newItems[processedItemId] = processedItem

      const newStatus = isSyncCompleted(newItems) ? SYNC_STATUS_COMPLETED : state.status

      return {
        ...state,
        items: newItems,
        // Remove all errors related to the successfully processed item
        errors: _.filter(state.errors, error => error.itemId !== processedItemId),
        status: newStatus,
      }
    }

    case 'QUEUE_ITEM_PROCESSED_FAILURE': {
      const { id: processedItemId, error: itemErrorMessage } = action
      const processedItem = _.find(state.items, item => item.id === processedItemId)
      processedItem.completed_at = Date.now()

      const newItems = { ...state.items }
      newItems[processedItemId] = processedItem

      const newStatus = isSyncCompleted(newItems) ? SYNC_STATUS_COMPLETED : state.status

      // Remove all old errors related to current processed item
      const newErrors = _.filter(state.errors, error => error.itemId !== processedItemId)
      // and add the new one
      newErrors.push({
        itemId: processedItemId,
        message: itemErrorMessage,
      })

      return {
        ...state,
        items: newItems,
        errors: newErrors,
        status: newStatus,
      }
    }

    case 'RESET_QUEUE': {
      return {
        ...state,
        items: {},
        errors: [],
        status: SYNC_STATUS_IDLE,
      }
    }

    default:
      return state
  }
}

export default reducer
