import _ from 'lodash'

const initialState = {
  entities: {}, // Store projects data
  mapping: {}, // Store projects mapping data (informations about relation between projects, configuration categories and products)
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Projects reducers
    case 'RPM/PROJECTS_ADDED':
      return {
        ...state,
        entities: { ...state.entities, ...action.projects },
      }
    case 'RPM/PROJECT_UPDATED':
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.id]: { ...state.entities[action.id], ...action.attributes },
        },
      }
    case 'RPM/PROJECT_REMOVED': {
      const newEntities = { ...state.entities }
      delete newEntities[action.id]

      return {
        ...state,
        entities: newEntities,
      }
    }

    // Configuration categories reducers
    case 'RPM/CONFIGURATION_CATEGORY_CREATED': {
      const projectId = action.item.project_id
      const project = { ...state.entities[projectId] }
      project.configuration_categories.push(action.item)
      return {
        ...state,
        entities: {
          ...state.entities,
          [projectId]: project,
        },
      }
    }

    case 'RPM/CONFIGURATION_CATEGORY_UPDATED': {
      const { item } = action
      const projectId = item.project_id
      const project = { ...state.entities[projectId] }

      const configurationCategoryIndex = _.findIndex(project.configuration_categories, o => o.id === item.id)
      const configurationCategory = project.configuration_categories[configurationCategoryIndex]
      project.configuration_categories[configurationCategoryIndex] = { ...configurationCategory, ...item }

      return {
        ...state,
        entities: {
          ...state.entities,
          [projectId]: project,
        },
      }
    }

    case 'RPM/CONFIGURATION_CATEGORY_REMOVED': {
      const { configurationCategoryId, projectId } = action

      const project = { ...state.entities[projectId] }
      project.configuration_categories = _.filter(
        project.configuration_categories,
        o => o.id !== configurationCategoryId
      )

      const mapping = _.filter(
        state.mapping[projectId],
        item => item.configuration_category_id !== configurationCategoryId
      )

      return {
        ...state,
        entities: {
          ...state.entities,
          [projectId]: project,
        },
        mapping: {
          ...state.mapping,
          [projectId]: mapping,
        },
      }
    }

    // Projects mapping reducers
    case 'RPM/PRODUCTS_MAPPED_TO_PROJECT': {
      const copy = { ...state.mapping }

      if (copy[action.projectId] === undefined) {
        copy[action.projectId] = []
      }

      _.forEach(action.added, element => {
        const alreadyMapped = _.find(
          copy[action.projectId],
          item =>
            item.product_id === element.product_id &&
            item.configuration_category_id === element.configuration_category_id
        )

        if (!alreadyMapped) {
          copy[action.projectId].push(element)
        }
      })

      _.forEach(action.removed, element => {
        _.remove(
          copy[action.projectId],
          item =>
            item.product_id === element.product_id &&
            item.configuration_category_id === element.configuration_category_id
        )
      })

      return {
        ...state,
        mapping: {
          ...state.mapping,
          [action.projectId]: copy[action.projectId],
        },
      }
    }

    default:
      return state
  }
}

export default reducer
