import scoringStepsMap from '../../../components/scoring/_parts/helper/scoringStepsMap'

const initialState = {
  newScoringPanelVisible: false,
  newScoringId: 0,
  scoringCreationStep: scoringStepsMap.chooseTemplate,
  selectedTemplate: 0,
  selectedTemplateLocales: [],
  scoringScope: {},
  mandatoryProps: [],
  selectedStatus: '',
  project_mode: false,
  publishTo: [],
  coverPicture: {},
  fullExterior: {},
  fullInterior: {},
  hmi: {},
  cargo: {},
  scoringProps: [],
  scoringPictures: [],
  isBrandFocused: false,
  showDetailedSpecifications: false,
  scoringSearch: {
    pageSize: 6,
    currentPage: 0,
    pagesCount: 0,
    searchValue: '',
    hits: [],
  },
  nodes: {},
  nodeDefinitions: {},
  duplicate: {
    popupVisible: false,
    sourceScoringId: 0,
    sourceProductName: '',
    mainPopupDisabled: false,
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_SCORING_PANEL_SETTINGS':
      return initialState
    case 'UPDATE_NEW_SCORING_PANEL_VISIBLE':
      return {
        ...state,
        newScoringPanelVisible: action.newScoringPanelVisible,
        newScoringId: action.newScoringId,
      }
    case 'UPDATE_SCORING_SEARCH_CURRENT_PAGE':
      return {
        ...state,
        scoringSearch: {
          ...state.scoringSearch,
          currentPage: action.currentPage,
        },
      }
    case 'UPDATE_SCORING_SEARCH':
      return {
        ...state,
        scoringSearch: {
          ...state.scoringSearch,
          searchValue: action.searchValue,
          currentPage: action.currentPage,
          pagesCount: action.pagesCount,
          hits: action.hits,
        },
      }
    case 'UPDATE_PAGES_COUNT':
      return {
        ...state,
        scoringSearch: {
          ...state.scoringSearch,
          pagesCount: action.pagesCount,
        },
      }
    case 'UPDATE_SELECTED_TEMPLATE':
      return {
        ...state,
        selectedTemplate: action.selectedTemplate,
      }
    case 'UPDATE_SELECTED_TEMPLATE_DATA':
      return {
        ...state,
        selectedTemplate: action.selectedTemplate,
        selectedTemplateLocales: action.selectedTemplateLocales,
        scoringScope: action.scoringScope,
        mandatoryProps: action.mandatoryProps,
        nodes: action.scoringNodes,
        nodeDefinitions: action.nodeDefinitions,
        selectedStatus: action.selectedStatus,
      }
    case 'INIT_SCORING_FOR_EDIT':
      return {
        ...state,
        newScoringId: action.newScoringId,
        selectedTemplate: action.selectedTemplate,
        selectedTemplateLocales: action.selectedTemplateLocales,
        scoringScope: action.scoringScope,
        scoringProps: action.scoringProps,
        selectedStatus: action.selectedStatus,
        publishTo: action.publishTo,
        coverPicture: action.coverPicture,
        fullExterior: action.fullExterior,
        fullInterior: action.fullInterior,
        hmi: action.hmi,
        cargo: action.cargo,
        nodes: action.scoringNodes,
        nodeDefinitions: action.nodeDefinitions,
        project_mode: action.project_mode,
        mandatoryProps: action.mandatoryProps,
      }
    case 'UPDATE_SELECTED_STATUS':
      return {
        ...state,
        selectedStatus: action.selectedStatus,
      }
    case 'UPDATE_PUBLISH_TO':
      return {
        ...state,
        publishTo: action.publishTo,
      }
    case 'UPDATE_COVER_PICTURE':
      return {
        ...state,
        coverPicture: action.coverPicture,
      }
    case 'UPDATE_FULL_EXTERIOR':
      return {
        ...state,
        fullExterior: action.fullExterior,
      }
    case 'UPDATE_FULL_INTERIOR':
      return {
        ...state,
        fullInterior: action.fullInterior,
      }
    case 'UPDATE_HMI':
      return {
        ...state,
        hmi: action.hmi,
      }
    case 'UPDATE_CARGO':
      return {
        ...state,
        cargo: action.cargo,
      }
    case 'UPDATE_SCORING_PICTURES':
      return {
        ...state,
        scoringPictures: action.scoringPictures,
      }
    case 'UPDATE_SCORING_CREATION_STEP':
      return {
        ...state,
        scoringCreationStep: action.scoringCreationStep,
      }
    case 'UPDATE_SCORING_PROPS':
      return {
        ...state,
        scoringProps: action.scoringProps,
      }
    case 'UPDATE_BRAND_FOCUSED':
      return {
        ...state,
        isBrandFocused: action.isBrandFocused,
      }
    case 'UPDATE_SHOW_DETAILED_SPECIFICATIONS': {
      return {
        ...state,
        showDetailedSpecifications: action.showDetailedSpecifications,
      }
    }
    case 'UPDATE_NODES':
      return {
        ...state,
        nodes: action.nodes,
      }
    case 'UPDATE_SCORING_DUPLICATE_PROPS':
      return {
        ...state,
        duplicate: {
          ...state.duplicate,
          popupVisible: action.popupVisible,
          sourceScoringId: action.sourceScoringId,
          sourceProductName: action.sourceProductName,
          mainPopupDisabled: action.mainPopupDisabled,
        },
      }
    case 'UPDATE_PROJECT_MODE':
      return {
        ...state,
        project_mode: action.projectMode,
      }
    default:
      return state
  }
}

export default reducer
