const initialState = {
  addProductPopup: {
    visible: false,
    showSelectedEnabled: false,
  },
  bestWorstEnabled: false,
  differencesEnabled: false,
  order: [],
  chosenProductsForComparison: [],
  comparedProducts: [],
  referenceTemplate: {},
  deltaProduct: {
    id: null,
    scoreSet: null,
  },
  itemsFather: {},
  navigationLists: {},
  nodePopup: {
    isLoading: false,
    visible: false,
    type: null,
    nodeDefId: 0,
  },
  syncMedia: false,
}
export default initialState
