const initialState = {
  clipboard: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_CLIPBOARD':
      return {
        ...state,
        clipboard: action.clipboard,
      }

    default:
      return state
  }
}

export default reducer
