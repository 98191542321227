const initialState = {
  blocks: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_HOME_DATA':
      return {
        ...state,
        blocks: action.blocks,
      }
    default:
      return state
  }
}

export default reducer
