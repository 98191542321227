const initialState = {
  entities: {},
  isLoading: false,
  params: {
    query: '',
    orderBy: 'created_at_desc',
  },
  pagination: {
    page: 1,
    perPage: 72,
    total: null,
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RPM/SEARCH_PROJECTS/SEARCH_INIT':
      return {
        ...state,
        isLoading: true,
      }
    case 'RPM/SEARCH_PROJECTS/SEARCHED':
      return {
        ...state,
        entities: action.entities,
        isLoading: false,
        pagination: { ...state.pagination, ...action.pagination },
      }
    case 'RPM/SEARCH_PROJECTS/DELETED_SEARCHED_PROJECT': {
      const updatedEntities = { ...state.entities }
      delete updatedEntities[action.id]
      return {
        ...state,
        entities: updatedEntities,
      }
    }
    case 'RPM/SEARCH_PROJECTS/PARAMS_UPDATED':
      return {
        ...state,
        params: { ...state.params, ...action.params },
      }
    case 'RPM/SEARCH_PROJECTS/PAGINATION_PARAMS_UPDATED':
      return {
        ...state,
        pagination: { ...state.pagination, ...action.pagination },
      }
    default:
      return state
  }
}

export default reducer
