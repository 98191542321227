const initialState = {
  orientation: {
    isPortrait: false,
    isLandscape: false,
  },
  size: {
    innerWidth: 0,
    innerHeight: 0,
    outerWidth: 0,
    outerHeight: 0,
  },
  isOnline: true,
  platform: '',
  iOS: false,
  isSafari: false,
  isFirefox: false,
  screenType: 0,
  isStickyHeader: false,
  scrollDisabled: false,
  burgerMenuVisible: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_DEVICE_SETTINGS':
      return {
        ...state,
        orientation: action.orientation,
        size: action.size,
        platform: action.platform,
        iOS: action.iOS,
        isSafari: action.isSafari,
        isFirefox: action.isFirefox,
        screenType: action.screenType,
      }
    case 'SET_STICKY_HEADER_STATUS':
      return {
        ...state,
        isStickyHeader: action.isStickyHeader,
      }
    case 'UPDATE_IS_ONLINE':
      return {
        ...state,
        isOnline: action.isOnline,
      }
    case 'UPDATE_SCROLL_DISABLED_STATUS':
      return {
        ...state,
        scrollDisabled: action.scrollDisabled,
      }
    case 'UPDATE_BURGER_MENU_VISIBLE':
      return {
        ...state,
        burgerMenuVisible: action.burgerMenuVisible,
      }
    default:
      return state
  }
}

export default reducer
