const initialState = {
  // View
  reportPage: 0,
  reportDetailSection: {},
  reportSidebarVisible: true,
  reportItemOpened: [],
  media: [],
  reportOpened: {},
  reportMessages: {},
  // filter order
  reportFiltersGlobal: {
    best: 50,
    worst: 50,
  },
  reportFiltersList: {},
  reportOrder: {},

  // Model
  reportObj: {
    confidential: false,
    texts: {},
    nodes: {},
    top_lowest: {},
    analytics: {},
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // VIEW
    case 'UPDATE_REPORT_PAGE_SELECTED':
      return {
        ...state,
        reportPage: action.reportPage,
      }
    case 'UPDATE_REPORT_DETAIL_SECTION_SELECTED':
      return {
        ...state,
        reportDetailSection: action.reportDetailSection,
      }
    case 'UPDATE_REPORT_SIDEBAR_VISIBILITY':
      return {
        ...state,
        reportSidebarVisible: action.reportSidebarVisible,
      }

    case 'UPDATE_REPORT_OPENED':
      return {
        ...state,
        reportOpened: action.reportOpened,
      }
    case 'UPDATE_REPORT_FILTERS_LIST':
      return {
        ...state,
        reportFiltersList: action.reportFiltersList,
      }
    case 'UPDATE_REPORT_FILTERS_GLOBAL':
      return {
        ...state,
        reportFiltersGlobal: action.reportFiltersGlobal,
      }
    case 'UPDATE_REPORT_ORDER':
      return {
        ...state,
        reportOrder: action.reportOrder,
      }
    case 'UPDATE_REPORT_MESSAGES':
      return {
        ...state,
        reportMessages: action.reportMessages,
      }
    case 'UPDATE_REPORT_MEDIA':
      return {
        ...state,
        media: action.media,
      }

    // MODEL
    case 'UPDATE_REPORT':
      return {
        ...state,
        reportObj: { ...state.reportObj, ...action.reportObj },
      }
    case 'UPDATE_REPORT_TEXTS':
      return {
        ...state,
        reportObj: {
          ...state.reportObj,
          texts: action.texts,
        },
      }
    case 'UPDATE_REPORT_TOP_LOWEST':
      return {
        ...state,
        reportObj: {
          ...state.reportObj,
          top_lowest: action.top_lowest,
        },
      }

    default:
      return state
  }
}

export default reducer
