const initialState = {
  msgboxMessage: '',
  data: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_MSGBOX':
      return {
        ...state,
        msgboxMessage: action.msgboxMessage,
        data: action.data,
      }
    default:
      return state
  }
}

export default reducer
