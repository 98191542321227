const initialState = {
  comments: {},
  newComments: {},
  newCommentsForecast: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_NEW_COMMENTS':
      return {
        ...state,
        newComments: action.newComments,
      }
    case 'UPDATE_NEW_COMMENTS_FORECAST':
      return {
        ...state,
        newCommentsForecast: action.newCommentsForecast,
      }
    case 'UPDATE_COMMENTS':
      return {
        ...state,
        comments: action.comments,
      }

    case 'UPDATE_COMMENTS_AND_NEW_COMMENTS':
      return {
        ...state,
        comments: action.comments,
        newComments: action.newComments,
      }

    case 'UPDATE_COMMENTS_AND_NEW_COMMENTS_FORECAST':
      return {
        ...state,
        comments: action.comments,
        newCommentsForecast: action.newCommentsForecast,
      }
    default:
      return state
  }
}

export default reducer
