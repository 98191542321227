import _ from 'lodash'

const initialState = {
  id: null,
  touchedAttributes: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RPM/EDIT_PROJECT/PROJECT_FETCHED':
      return {
        ...state,
        id: action.id,
      }

    case 'RPM/EDIT_PROJECT/PROJECT_ATTRIBUTES_CHANGED':
      return {
        ...state,
        touchedAttributes: { ...state.touchedAttributes, ...action.attributes },
      }

    case 'RPM/EDIT_PROJECT/PROJECT_ATTRIBUTES_RESET': {
      const newAttributes = { ...state.touchedAttributes }
      _.unset(newAttributes, action.attribute)

      return {
        ...state,
        touchedAttributes: newAttributes,
      }
    }

    case 'RPM/PROJECT_UPDATED':
      return {
        ...state,
        touchedAttributes: action.id === state.id ? {} : state.touchedAttributes,
      }

    default:
      return state
  }
}

export default reducer
