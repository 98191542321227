const initialState = {
  lastProduct: {},
  media: [],
  metrics: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'DSH/SET_LAST_PRODUCT':
      return {
        ...state,
        lastProduct: action.lastProduct,
      }
    case 'DSH/SET_MEDIA':
      return {
        ...state,
        media: action.media,
      }
    case 'DSH/SET_METRICS':
      return {
        ...state,
        metrics: {
          ...state.metrics,
          ...action.metrics,
        },
      }
    default:
      return state
  }
}

export default reducer
