import _ from 'lodash'

const initialState = {
  entities: [],
  selectedIds: [],
  isLoading: false,
  params: {
    query: '',
    orderBy: 'az_asc',
    includeConfigurations: true,
    includeBenchmarks: true,
    templateId: null,
    projectId: null,
    excludedProductIds: null,
  },
  pagination: {
    page: 1,
    perPage: 10,
    total: null,
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RPM/SEARCH_PRODUCTS/SEARCH_INIT':
      return {
        ...state,
        isLoading: true,
      }
    case 'RPM/SEARCH_PRODUCTS/SEARCHED':
      return {
        ...state,
        entities: action.entities,
        isLoading: false,
        pagination: {
          ...state.pagination,
          ...action.pagination,
        },
      }
    case 'RPM/SEARCH_PRODUCTS/PARAMS_UPDATED':
      return {
        ...state,
        params: { ...state.params, ...action.params },
      }
    case 'RPM/SEARCH_PRODUCTS/PAGINATION_PARAMS_UPDATED':
      return {
        ...state,
        pagination: { ...state.pagination, ...action.pagination },
      }
    case 'RPM/SEARCH_PRODUCTS/INIT':
      return {
        ...state,
        params: {
          ...state.params,
          templateId: action.templateId,
        },
        selectedIds: action.selectedIds,
      }
    case 'RPM/SEARCH_PRODUCTS/PRODUCT_SELECTED':
      return {
        ...state,
        params: {
          ...state.params,
          templateId: action.templateId,
        },
        selectedIds: [...state.selectedIds, action.productId],
      }
    case 'RPM/SEARCH_PRODUCTS/PRODUCT_DESELECTED': {
      const newSelectedIds = _.without(state.selectedIds, action.productId)
      return {
        ...state,
        params: {
          ...state.params,
          templateId: newSelectedIds.length > 0 ? state.params.templateId : null,
        },
        selectedIds: newSelectedIds,
      }
    }
    case 'RPM/SEARCH_PRODUCTS/PRODUCT_DESELECTED_ALL':
      return {
        ...state,
        selectedIds: [],
      }
    default:
      return state
  }
}

export default reducer
