const initialState = {
  lang: '',
  locales: [],
  defaultLang: process.env.defaultLang,
  systemLoaded: false,
  cobranding: {},
  config: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_SYSTEM_LOAD_STATUS':
      return {
        ...state,
        systemLoaded: action.systemLoaded,
      }
    case 'UPDATE_LOCALES':
      return {
        ...state,
        ...state,
        locales: [...state.locales, ...action.locales],
      }
    case 'UPDATE_SYSTEM_LANG':
      return {
        ...state,
        lang: action.lang,
      }

    case 'UPDATE_ENV':
      return {
        ...state,
        config: action.config,
        cobranding: action.cobranding,
      }

    default:
      return state
  }
}

export default reducer
