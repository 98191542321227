const initialState = {
  scoring: {},
  scoringMode: 0,
  completedPercentage: 0,
  nodeDefsObj: {},
  locales: {},
  defKeys: [],
  scopeProps: [],
  sidebarVisible: true,
  itemsFather: {},
  itemView: 0,
  comments: [],
  commentFocused: {
    nodeDefId: '',
    status: 0,
  },
  header: {
    featuredImg: {},
    brandProps: {},
    actionsMenuVisible: false,
    calculationTree: {
      loaded: false,
      perimeters: [],
    },
  },
  analytics: {
    lastClickedNode: {
      type: 0,
      id: 0,
    },
    selectedReferenceType: false,
    benchmarkId: 0,
    benchmarkNodes: [],
    breadcrumb: [],
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_SCORING_TREE':
      return initialState
    case 'INIT_SCORING_TREE':
      return {
        ...state,
        scoring: action.scoring,
        nodeDefsObj: action.nodeDefsObj,
        locales: action.locales,
        scopeProps: action.scopeProps,
        defKeys: action.defKeys,
        itemsFather: action.itemsFather,
      }
    case 'UPDATE_SCORING_TREE_FEATURE_IMAGE':
      return {
        ...state,
        header: {
          ...state.header,
          featuredImg: action.featuredImg,
        },
      }
    case 'UPDATE_SCORING_TREE_BRAND_PROPS':
      return {
        ...state,
        header: {
          ...state.header,
          brandProps: action.brandProps,
        },
      }
    case 'UPDATE_ACTION_MENU_VISIBLE':
      return {
        ...state,
        header: {
          ...state.header,
          actionsMenuVisible: action.actionsMenuVisible,
        },
      }
    case 'UPDATE_SCORING_MODE_SELECTED':
      return {
        ...state,
        scoringMode: action.scoringMode,
      }
    case 'UPDATE_CALCULATION_MODE':
      return {
        ...state,
        calculationMode: action.calculationMode,
      }
    case 'UPDATE_SCORING_HEADER_CALCULATION_TREE':
      return {
        ...state,
        header: {
          ...state.header,
          calculationTree: action.calculationTree,
        },
      }

    case 'UPDATE_SCORING_TREE_SCORING_NODES':
      return {
        ...state,
        nodeDefsObj: action.nodeDefsObj,
      }

    case 'UPDATE_SCORING_TREE_ITEMS_FATHER':
      return {
        ...state,
        itemsFather: action.node,
      }
    case 'UPDATE_SIDEBAR_VISIBLE':
      return {
        ...state,
        sidebarVisible: action.sidebarVisible,
      }

    case 'UPDATE_SCORING_INTO_SCORINGTREE':
      return {
        ...state,
        scoring: action.scoring,
      }

    case 'UPDATE_SCORING_INTO_SCORINGTREE_AND_ITEMS_FATHER':
      return {
        ...state,
        scoring: action.scoring,
        itemsFather: action.itemsFather,
        itemView: action.itemView,
      }

    case 'UPDATE_COMPLETED_PERCENTAGE_INTO_SCORINGTREE':
      return {
        ...state,
        completedPercentage: action.completedPercentage,
      }

    case 'UPDATE_SCORING_TREE_AFTER_ACTIVE_ITEM_UPDATED':
      return {
        ...state,
        scoring: action.scoring,
        itemView: action.itemView,
      }

    case 'UPDATE_SCORING_TREE_ITEM_AND_CRITERIA_VIEW_PROPS':
      return {
        ...state,
        scoring: action.scoring,
        itemView: action.itemView,
      }
    case 'UPDATE_ANALYTICS':
      return {
        ...state,
        analytics: action.analytics,
      }

    case 'UPDATE_COMMENT_FOCUSED':
      return {
        ...state,
        commentFocused: action.commentFocused,
      }

    case 'UPDATE_SCORING_TREE_COMMENTS':
      return {
        ...state,
        comments: action.comments,
      }

    default:
      return state
  }
}

export default reducer
