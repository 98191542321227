const initialState = {
  scorings: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_DOWNLOADED':
      return {
        ...state,
        scorings: action.scorings,
      }

    default:
      return state
  }
}

export default reducer
