const initialState = {
  status: 0,
  data: {},
  syncStack: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_SYNC_STATUS':
      return {
        ...state,
        status: action.status,
      }
    case 'UPDATE_SYNC_DATA':
      return {
        ...state,
        data: action.data,
      }
    case 'UPDATE_SYNC_UI_PROPS':
      return {
        ...state,
        status: action.status,
        data: action.data,
        syncStack: action.syncStack,
      }
    default:
      return state
  }
}

export default reducer
