import _ from 'lodash'

const initialState = {
  entities: {}, // Indexed by product_id
  media: {}, // Indexed by product_id
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Projects reducers
    case 'RPM/PRODUCTS_ADDED':
      return {
        ...state,
        entities: { ...state.entities, ...action.products },
      }
    case 'RPM/PRODUCT_UPDATED':
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.id]: { ...state.entities[action.id], ...action.attributes },
        },
      }
    case 'RPM/MEDIA_ADDED': {
      const { productId, media } = action

      const currentMedia = _.has(state.media, productId) ? state.media[productId] : []
      const newIds = _.keys(_.keyBy(media, 'id'))
      const filteredMedia = _.filter(currentMedia, _media => !newIds.includes(_media.id))

      return {
        ...state,
        media: {
          ...state.media,
          [productId]: [...filteredMedia, ...media],
        },
      }
    }
    case 'RPM/PRODUCT_REMOVED': {
      const newEntities = { ...state.entities }
      delete newEntities[action.id]

      return {
        ...state,
        entities: newEntities,
      }
    }
    default:
      return state
  }
}

export default reducer
